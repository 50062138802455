
import { Component, Vue } from 'vue-property-decorator'

import '@/assets/scss/style.scss'

import { dispatchLogIn } from '@/store/main/actions'
import ResetPasswordModal from '@/components/Landing/ResetPasswordModal.vue'
import ResetPasswordBlock from '@/components/Landing/blocks/ResetPasswordBlock.vue'
import HeaderBlock from '@/components/Landing/blocks/HeaderBlock.vue'

import FormBlock from '@/components/Landing/blocks/FormBlock.vue'
import FooterBlock from '@/components/Landing/blocks/FooterBlock.vue'
import SocialsBlock from '@/components/Landing/blocks/SocialsBlock.vue'
import Spinner from '@/components/Spinner.vue'

@Component({
  components: {
    ResetPasswordModal,
    ResetPasswordBlock,
    SocialsBlock,
    FooterBlock,
    HeaderBlock,
    FormBlock,
    Spinner,
  },
})
export default class Login2 extends Vue {
  showForm = false

  public email: string = ''
  public password: string = ''
  public code: any = null
  public formError: boolean = false
  public loginError: boolean = false
  public loadingState: any = {
    submit: false,
  }

  public showResetPassword: boolean = false

  openForm() {
    this.showForm = true
  }

  closeForm() {
    this.showForm = false
  }

  public validateData() {
    if (!this.email || !this.password) {
      this.formError = true
    } else {
      this.formError = false
    }
    return !this.formError
  }

  public handleOpenResetPassword() {
    console.debug('%c handleOpenResetPassword', 'color:lime;')
    this.showResetPassword = true

  }

  public handleCloseResetPassword() {
    console.debug('%c handleCloseResetPassword', 'color:red;')
    this.showResetPassword = false
  }

  public async handleSubmit() {
    this.loginError = false
    if (!await this.validateData()) {
      return null
    }
    const result = await dispatchLogIn(this.$store, { username: this.email, password: this.password, code: this.code })
    if (result) {
      window.location.pathname = '/dashboard'
    } else {
      this.loginError = true
    }
  }

  mounted() {
    window.scrollTo(0, 0)
  }
}
