
import { Component, Vue, Prop } from 'vue-property-decorator'
import anime from 'animejs'

import { dispatchSubmitResetPassword } from '@/store/main/actions'
import Spinner from '@/components/Spinner.vue'
import IconClose from '@/components/IconClose.vue'

@Component({
  components: {
    IconClose,
    Spinner,
  },
})
export default class ResetPasswordBlock extends Vue {
  overlay: any = null
  form: any = null

  public loadingState: any = {
    submit: false,
  }
  public resetEmail: string = ''
  public formError: boolean = false
  public resetError: boolean = false
  public resetOK: boolean = false

  public validateForm() {
    if (!this.resetEmail) {
      this.formError = true
    } else {
      this.formError = false
    }
    return !this.formError
  }

  public async handleSubmit() {
    this.resetError = false
    if (await this.validateForm()) {
      this.loadingState.submit = true
      const response = await dispatchSubmitResetPassword(this.$store, { email: this.resetEmail })
      if (response) {
        this.resetOK = true
      } else {
        this.resetError = true
        // this.$toast.error(this.$t('Error').toString());
      }
      this.loadingState.submit = false
    }
  }

  public showForm() {
    if (this.overlay && this.form) {
      const tl = anime.timeline({
        duration: 400,
        easing: 'linear',
      });

      tl.add({
        targets: this.overlay,
        opacity: [0, 1],
        begin: () => {
          this.overlay.style.zIndex = 999
        },
      });

      tl.add({
        targets: this.form,
        opacity: [0, 1],
        begin: () => {
          this.form.style.zIndex = 10000
        },
      });
    }
  }

  public closeForm() {
    if (this.overlay) {
      this.overlay.removeEventListener('click', this.closeForm)
    }
    if (this.overlay && this.form) {
      const tl = anime.timeline({
        duration: 400,
        easing: 'linear',
      });

      tl.add({
        targets: this.form,
        opacity: [1, 0],
        complete: () => {
          this.form.style.zIndex = -10000
        },
      });

      tl.add({
        targets: this.overlay,
        opacity: [1, 0],
        complete: () => {
          this.overlay.style.zIndex = -999
        },
      });
    }

    setTimeout(() => this.$emit('close-form'), 500)
  }

  async mounted() {
    this.overlay = document.getElementById('overlay')
    this.form = document.getElementById('form')

    if (this.overlay) {
      this.overlay.addEventListener('click', this.closeForm)
    }

    this.showForm()
  }
}
